<template>
    <div></div>
</template>

<script>
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

export default {
        mounted() {
            const cookieConsent = new CookieConsent();
            cookieConsent.run()
        }
}
</script>
